
// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import imageUrls from '../../images/gcsImages';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import SectionHeader from '../../components/headers/SectionHeader';

function ToolsLanding() {
    // gotta grab for correct scoreboard path
    const { userData, userTier, defaultCompetition } = useContext(GlobalContext);
    const isLoggedIn = userData.user ? true : false;
    const competitionId = defaultCompetition.value;
    const divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    const latestDate = (new Date()).toISOString().slice(0, 10).replace(/-/g, '');

    const cardData = [
        {
            header: 'Shot Charts App',
            subHeader: 'Visualize and analyze shooting performance with customizable shot charts for teams and individual players.',
            src: imageUrls.toolShotChartsHero,
            path: '/tools/shot-charts',
            tier: 0
        }, {
            header: 'NCAA Scoreboard',
            subHeader: `Stay updated with real-time scores across the league using our interactive daily scoreboard, and gain insights from a chosen day's performance.`,
            src: imageUrls.toolScoreboardHero,
            path: `/tools/scoreboard/${competitionId}/d${divisionId}/${latestDate}`,
            tier: 0
        }, {
            header: 'Team Scatter App',
            subHeader: 'Craft detailed scatter plots that depict team performance across various metrics, enabling comprehensive analysis of league-wide trends.',
            src: imageUrls.toolTeamScatterHero,
            path: '/tools/team-scatter',
            tier: 0
        }, {
            header: 'Player Scatter App',
            subHeader: `Assess and compare individual player stats with customizable scatter plots, shedding light on players' various strengths and weaknesses.`,
            src: imageUrls.toolPlayerScatterHero,
            path: '/tools/player-scatter',
            tier: 0
        }, {
            header: 'Bar Charts App',
            subHeader: 'Compare player and team statistics visually, using versatile and customizable bar charts tailored to your preferred metrics.',
            src: imageUrls.toolBarChartsHero,
            path: '/tools/bar-charts',
            tier: 1
        }, {
            header: 'Comparisons App',
            subHeader: 'Conduct side-by-side comparisons of players and teams, facilitating comprehensive performance assessments of up to 4 players or teams simultaneously.',
            src: imageUrls.toolComparisonsHero,
            path: '/tools/comparisons',
            tier: 1
        }, {
            header: 'Game Deciding Shots',
            subHeader: 'See which teams and players shoot the best on + have made the most game tying and go ahead shots.',
            src: imageUrls.toolTyingGoAheadHero,
            config: { imgStyle: { padding: 0 } },
            path: `/tools/game-deciding-shots/${competitionId}/d${divisionId}`,
            tier: 1
        }, {
            header: 'Streaks and Streak Stats',
            subHeader: 'Who has the longest uninterrupted scoring streaks? + stats on streaks',
            src: imageUrls.toolStreaksHero,
            config: { imgStyle: { padding: 0 } },
            path: `/tools/scoring-streaks/${competitionId}/d${divisionId}`,
            tier: 1
        }, {
            header: 'DIY Shot Charts App',
            subHeader: 'Utilize practice data to create shot charts from scratch for your team, helping to monitor player progress and potentially impact game strategies.',
            src: imageUrls.toolDiyShotChartsHero,
            path: '/tools/diy-shot-charts',
            tier: 2
        }, {
            header: 'Regression Analysis',
            subHeader: 'Perform regression analyses on game and season stats to identify factors most predictive of offensive and defensive ratings.',
            src: imageUrls.toolRegressionHero,
            path: '/tools/model-fitting',
            tier: 2
        }
    ];

    return (
        <Container className='shadow-container'>
            <SectionHeader
                title='CBB Analytics Toolbox:'
                subtitle='Customizable Tools for Deep Dives and Actionable Analytics'
            />

            <Container className='padded-inner-container' style={{ maxWidth: 1200 }}>
                <Row style={{ margin: '30px 0px', maxWidth: 975 }}>
                    <p style={{ fontSize: 'calc(0.15vw + 14px)' }}>
                        The CBB Analytics Toolbox offers a range of tools for fans, media, and coaching staffs. Our shot charts app, customizable scatter plot apps, and other tools let you create graphics and analyze player and team performance in depth. These tools provide flexibility for various analyses, from individual player stats to league-wide trends. Explore the different options to find the tools that suit your needs.
                    </p>
                </Row>
                <Row style={{ padding: 0, margin: '20px -10px 0px -10px' }}>
                    {cardData.map((card, index) => (
                        <Col key={index} xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} style={{ marginBottom: 25, padding: '0px 10px' }}>
                            <LandingPageCard
                                header={card.header}
                                subHeader={card.subHeader}
                                src={card.src}
                                path={card.path}
                                disabled={userTier.value <= card.tier}
                                config={card.config}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default ToolsLanding;
