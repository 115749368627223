
import React from 'react';
import { Container } from 'react-bootstrap';
import imageUrls from '../../images/gcsImages';

function SectionHeader({ title, subtitle }) {
    const headerStyles = {
        position: 'sticky',
        top: 48,
        maxHeight: 160,
        zIndex: 1000,
        maxWidth: '100%',
        background: '#f8f8f8',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${imageUrls.hexGridLight})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 0
    };

    const titleStyles = {
        fontFamily: 'Roboto Slab',
        fontWeight: 700,
        fontSize: 'calc(1.2vw + 21px)',
        lineHeight: 1.3,
        marginTop: 5
    };

    const subtitleStyles = {
        fontFamily: 'Roboto Slab',
        fontWeight: 700,
        fontSize: 'calc(1.2vw + 11px)',
        lineHeight: 1.3
    };

    return (
        <Container style={{ ...headerStyles }}>
            <div className='padded-inner-container' style={{ maxWidth: 1200, margin: 'auto', paddingBottom: 15 }}>
                <p style={titleStyles}>{title}</p>
                <p style={subtitleStyles}>{subtitle}</p>
            </div>
        </Container>
    );
}

export default SectionHeader;
