
// https://redketchup.io/image-resizer                          for re-sizing images
// https://onlinepngtools.com/convert-jpg-to-png                for adding transparency to pngs
// https://tinypng.com/                                         for minifying pngs
// https://new.express.adobe.com/tools/remove-background        for making transparent
// https://svgtrace.com/svg-to-png                              converting SVG to PNG (with ability to set size)
// https://cloudconvert.com/webp-to-png                         converting webp to png


const GCS_BUCKET = `https://storage.googleapis.com/cbb-image-files`;
const imageUrls = {
    // cbb assets, hero images, home page
    computers1: `${GCS_BUCKET}/cbb-assets/computers-1-min.png`,
    heroOriginal: `${GCS_BUCKET}/cbb-assets/hero-original.jpg`,
    heroMain: `${GCS_BUCKET}/cbb-assets/hero-openspace-blue-hex-min.jpg`,
    heroWide: `${GCS_BUCKET}/cbb-assets/hero-wide.png`,
    scoutingReports1: `${GCS_BUCKET}/cbb-assets/scouting-report-1-min.png`,
    scoutingReports2: `${GCS_BUCKET}/cbb-assets/scouting-report-2-min.png`,
    scoutingReports3: `${GCS_BUCKET}/cbb-assets/scouting-report-3-min.png`,
    scoutingReports4: `${GCS_BUCKET}/cbb-assets/scouting-report-4-min.png`,
    scoutingReports5: `${GCS_BUCKET}/cbb-assets/scouting-report-5-min.png`,
    dataVisBackground: `${GCS_BUCKET}/cbb-assets/hero-ball-court-hexes-min.jpg`,
    liveStatsByGenius: `${GCS_BUCKET}/cbb-misc/livestats-by-genius.png`,
    arrowPre: `${GCS_BUCKET}/cbb-icons/arrow-pre.svg`,
    arrowPost: `${GCS_BUCKET}/cbb-icons/arrow-post.svg`,
    arrowTrends: `${GCS_BUCKET}/cbb-icons/arrow-trend.svg`,
    blogPageHeaderBackground: `${GCS_BUCKET}/cbb-assets/hero-ball-hoop-hexes-min.jpg`,

    // scatterplot background images
    blackGraphBackground: `${GCS_BUCKET}/cbb-misc/textured-black-background.png`,
    texturedGrey: `${GCS_BUCKET}/cbb-misc/grey.avif`,
    texturedWhite: `${GCS_BUCKET}/cbb-misc/white.jpeg`,

    // blog pages
    blogPage2For1: `${GCS_BUCKET}/blog-posts/hero-2-for-1.jpg`,
    blogPage2fParticipation: `${GCS_BUCKET}/blog-posts/hero-2f-participation.jpg`,
    blogPageDontNeed3: `${GCS_BUCKET}/blog-posts/hero-dont-need-a-3-comeback.jpg`,
    blogPageFoulUp3: `${GCS_BUCKET}/blog-posts/hero-foul-up-3.jpg`,
    blogPageH1PredictsH2: `${GCS_BUCKET}/blog-posts/hero-h1-predicts-h2.jpg`,
    blogPageWinningTwitter: `${GCS_BUCKET}/blog-posts/hero-winning-twitter.png`,

    // payment card svgs
    amex: `${GCS_BUCKET}/payment-card-svgs/amex.svg`,
    visa: `${GCS_BUCKET}/payment-card-svgs/amex.svg`,
    discover: `${GCS_BUCKET}/payment-card-svgs/amex.svg`,
    mastercard: `${GCS_BUCKET}/payment-card-svgs/amex.svg`,

    // fallback images
    missingMale: `${GCS_BUCKET}/fallback-images/missing-male.png`,
    missingFemale: `${GCS_BUCKET}/fallback-images/missing-female.png`,
    missingPerson: `${GCS_BUCKET}/fallback-images/missing-person.png`,

    // headshots, logos prefix
    headshotPrefix: `${GCS_BUCKET}/player-headshots`,
    teamLogosPrefix: `${GCS_BUCKET}/team-logos`,
    confLogosPrefix: `${GCS_BUCKET}/conf-logos`,

    // cbb logo
    cbbLogoIconTransparent: `${GCS_BUCKET}/cbb-logo/cbb-logo-icon-transparent.svg`,
    cbbLogoIconWhite: `${GCS_BUCKET}/cbb-logo/cbb-logo-icon-white.svg`,
    cbbLogoBlack: `${GCS_BUCKET}/cbb-logo/cbb-logo-black.svg`,
    cbbLogoWhite: `${GCS_BUCKET}/cbb-logo/cbb-logo-white.svg`,
    cbbLogoLong: `${GCS_BUCKET}/cbb-logo/cbb-logo-long.svg`,
    // cbb-image-files/CBBLogoSoloMark is transparent
    // cbb-image-files/CBBLogoOnly.svg is not transparent, is white

    // base urls for different directories
    blogTwitterBase: `${GCS_BUCKET}/blog-posts/winning-twitter`,
    blogH1PredictsH2: `${GCS_BUCKET}/blog-posts/is-h1-predictive-of-h2`,
    testimonialsBase: `${GCS_BUCKET}/testimonial-headshots`,

    // miscellaneous
    rayngeHero: `${GCS_BUCKET}/cbb-misc/raynge-hero.jpeg`,
    hexGridDark: `${GCS_BUCKET}/cbb-misc/hex-grid-dark.png`,
    hexGridLight: `${GCS_BUCKET}/cbb-misc/hex-grid-light.png`,

    // about us page
    nickHeadshot: `${GCS_BUCKET}/cbb-assets/nick-headshot-minified.png`,
    nykLogo: `${GCS_BUCKET}/cbb-assets/knicks-logo-minified.png`,
    gswLogo: `${GCS_BUCKET}/cbb-assets/warriors-logo-minified.png`,
    stephKlay: `${GCS_BUCKET}/cbb-assets/steph-klay-minified.png`,
    hexBackground: `${GCS_BUCKET}/cbb-assets/hex-blend.svg`,

    // tools landing (images should be ~1.6125x aspect ratio)
    toolShotChartsHero: `${GCS_BUCKET}/cbb-misc/tools-landing/shot-chart-hero-v2.png`,
    toolBarChartsHero: `${GCS_BUCKET}/cbb-misc/tools-landing/bar-charts-hero-v2.png`,
    toolTeamScatterHero: `${GCS_BUCKET}/cbb-misc/tools-landing/team-scatter-hero-v2.png`,
    toolPlayerScatterHero: `${GCS_BUCKET}/cbb-misc/tools-landing/player-scatter-hero-v2.png`,
    toolDiyShotChartsHero: `${GCS_BUCKET}/cbb-misc/tools-landing/diy-shot-charts-hero-v2.png`,
    toolScoreboardHero: `${GCS_BUCKET}/cbb-misc/tools-landing/scoreboard-hero-v2.png`,
    toolTyingGoAheadHero: `${GCS_BUCKET}/cbb-misc/tools-landing/tying-goahead-hero-v2.png`,
    toolStreaksHero: `${GCS_BUCKET}/cbb-misc/tools-landing/streaks-hero-v2.png`,
    toolRegressionHero: `${GCS_BUCKET}/cbb-misc/tools-landing/regression-hero-v2.png`,
    toolComparisonsHero: `${GCS_BUCKET}/cbb-misc/tools-landing/comparisons-app-hero-v2.png`,

    // stats landing
    statsDraftProspects: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-draft-prospects-v2.jpg`,
    statsTransferPortal: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-transfer-portal-v2.jpg`,
    statsTransferHistory: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-transfer-history-v2.png`,
    statsTournamentStats: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-stats-v2.png`,
    statsDivisionStats: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-division-stats-v2.png`,
    statsConferenceStats: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-conference-stats-v2.png`,

    // tournament logos for stats landing
    statsTourneyJimmyV: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-jimmy-v-classic.png`,
    statsTourneyArmedForces: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-armed-forces-classic.png`,
    statsTourneyPuertoRico: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-puerto-rico.png`,
    statsTourneyBattle4Atl: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-battle-4-atlantis.png`,
    statsTourneyMauiInv: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-maui-invitational.png`,
    statsTourneyBig12Sec: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-big12-sec-challenge.jpeg`,
    statsTourneyBahaMar1: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-bahamar-hoops-1.jpeg`,
    statsTourneyBahaMar2: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-bahamar-hoops-2.png`,
    statsTourneyContinentalTire: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-continental-tire.png`,
    statsTourneyEmeraldCoast: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-emerald-coast.png`,
    statsTourneyEmpireClassic: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-empire-classic.png`,
    statsTourneyEspnInvtl: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-espn-invitational.png`,
    statsTourneyLegendsClassic: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-legends-classic.png`,
    statsTourneyVegasShowdown: `${GCS_BUCKET}/cbb-misc/stats-landing/hero-tourney-vegas-showdown.png`
};

export default imageUrls;
