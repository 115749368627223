// https://redketchup.io/image-resizer for image sizing
// https://onlinepngtools.com/convert-jpg-to-png for adding transparency to pngs
// https://tinypng.com/ for minifying pngs

// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import SectionHeader from '../../components/headers/SectionHeader';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import imageUrls from '../../images/gcsImages';


function StatsLanding() {
    const { userTier, userData, defaultCompetition } = useContext(GlobalContext);
    const isLoggedIn = userData.user ? true : false;
    const divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    const competitionId = defaultCompetition.value;
    const draftCompId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 36046 : 36045) : 36046;
    const tourneyCompId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 36046 : 36045) : 36046;
    const tournamentId = isLoggedIn ? (userData.user.team.gender === 'MALE' ? 2225 : 2227) : 2225;

    const cardData = [
        {
            header: 'Division Stats',
            subHeader: 'The top performing players, teams, lineups and player on-off stats for each division. There are our full Division-I, II and III leaderboards.',
            src: imageUrls.statsDivisionStats,
            path: `/stats/${competitionId}/division/d${divisionId}/team-box`,
            tier: 1
        }, {
            header: 'Conference Stats',
            subHeader: 'A rundown of the best performing conferences.',
            src: imageUrls.statsConferenceStats,
            path: `/stats/conference-home/${competitionId}`,
            tier: 0
        }, {
            header: 'Draft Prospects',
            subHeader: 'A rundown of the top 50 - 100 potential WNBA and NBA draft prospects each year, as listed by various top 100 lists.',
            src: imageUrls.statsDraftProspects,
            path: `/stats/${draftCompId}/draft/player-box`,
            tier: 0
        }, {
            header: 'Tournament Stats',
            subHeader: 'Tournament-specific Stats: Detailed statistics for individual tournaments.',
            src: imageUrls.statsTournamentStats,
            path: `/stats/${tourneyCompId}/tournaments/${tournamentId}/team-box`,
            tier: 1
        }, {
            header: 'Transfer Portal',
            subHeader: 'Active Transfers: Stats and details on players currently in the transfer portal.',
            src: imageUrls.statsTransferPortal,
            path: '/stats/portal/overview',
            tier: 2
        }, {
            header: 'Transfer History',
            subHeader: `An overview of teams' performance in the transfer portal over the years. Additionally, who were the best incoming & outgoing transfers.`,
            src: imageUrls.statsTransferHistory,
            path: `/stats/${competitionId}/transfers/${divisionId}/overview`,
            tier: 2
        }
    ];


    // And return
    return (
        <Container className='shadow-container'>
            <SectionHeader
                title='CBB Stats Central:'
                subtitle='Comprehensive Statistics for In-Depth Analysis'
            />

            <Container className='padded-inner-container' style={{ maxWidth: 1200 }}>
                <Row style={{ margin: '30px 0px', maxWidth: 900 }}>
                    <p>
                        CBB Stats Central provides comprehensive statistical resources including Division leaderboards, conference comparisons, draft prospects, tournament breakdowns, and transfer portal insights. Each section offers in-depth data and multiple views, allowing for thorough analysis of players, teams, and broader trends across college basketball. Whether you're tracking top performers, analyzing conference strengths, or diving into transfer impacts, our stats hub offers the depth and breadth needed for comprehensive basketball analysis.
                    </p>
                </Row>

                <Row style={{ padding: 0, margin: '20px -10px 0px -10px' }}>
                    {cardData.map((card, index) => (
                        <Col key={index} xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }} style={{ marginBottom: 25, padding: '0px 10px' }}>
                            <LandingPageCard
                                header={card.header}
                                subHeader={card.subHeader}
                                src={card.src}
                                path={card.path}
                                disabled={userTier.value < card.tier}
                                config={card.config}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default StatsLanding;
