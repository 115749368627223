import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

// Create The Component
function UnsubscribePage() {
    // Grab Params
    const params = useParams();
    const { token } = params;

    const [email, setEmail] = useState('');
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [didUnsubscribe, setDidUnsubscribe] = useState(false);
    const [didSubscribe, setDidSubscribe] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location]);

    // create handleUnsubscribe function that calls the backend
    const handleUnsubscribe = async () => {
        console.log('email: ', email);
        if (!email) { return; }
        setIsUnsubscribing(true);

        try {
            console.log('email: ', email);
            const apiBaseUrl = config.url.API_URL;
            await axios.post(`${apiBaseUrl}/email/unsubscribe`, { email });
            setDidUnsubscribe(true);
            setDidSubscribe(false);
            // Handle success (e.g., show a success message)
        } catch (error) {
            console.error('Error unsubscribing:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setIsUnsubscribing(false);
        }
    };

    const handleSubscribe = async () => {
        console.log('email: ', email);
        if (!email) { return; }
        setIsSubscribing(true);

        try {
            console.log('email: ', email);
            const apiBaseUrl = config.url.API_URL;
            await axios.post(`${apiBaseUrl}/email/subscribe`, { email });
            setDidSubscribe(true);
            setDidUnsubscribe(false);
        } catch (error) {
            console.error('Error subscribing:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setIsSubscribing(false);
        }
    };


    // Create Submit Button
    // let isEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
    const unsubscribeButton =
        (<div
            className={`cbb-submit-button small`}
            style={{ width: 115 }}
            onClick={handleUnsubscribe}
        >
            {`${isUnsubscribing ? 'Unsubscribing...' : 'Unsubscribe'}`}
        </div>);

    // create subscribe button
    const subscribeButton =
        (<div
            className={`cbb-submit-button small`}
            style={{ width: 115 }}
            onClick={handleSubscribe}
        >
            {`${isSubscribing ? 'Subscribing...' : 'Subscribe'}`}
        </div>);

    const showAlert = (didUnsubscribe || didSubscribe);

    return (
        <Container style={{ maxWidth: '100%' }}>

            <Container style={{ width: 450, marginTop: 20 }}>
                {showAlert &&
                    <Alert
                        variant='success'
                        style={{
                            border: '1px solid #218838',
                            paddingTop: '8px',
                            paddingBottom: '8px'
                        }}
                    >
                        You've been successfully {didUnsubscribe ? 'unsubscribed' : 'subscribed'}.
                    </Alert>
                }

                {!didUnsubscribe && <>
                    <Row style={{ marginTop: showAlert ? 188 : 250 }} />
                    <Row style={{ margin: 0 }}>
                        <p style={{ fontSize: '1.20em', fontWeight: 700 }}>Do you want to unsubscribe from our messages?</p>
                    </Row>
                    <Row style={{ margin: 0, fontSize: '0.98em', marginTop: 6 }}>
                        <p>You'll stop receiving messages from us.</p>
                    </Row>
                    <Row style={{ margin: 0, marginTop: 18 }}>
                        {unsubscribeButton}
                    </Row>
                </>}

                {didUnsubscribe && <>
                    <Row style={{ marginTop: showAlert ? 188 : 250 }} />
                    <Row style={{ margin: 0 }}>
                        <p style={{ fontSize: '1.20em', fontWeight: 700 }}>Re-subscribe to receive our messages</p>
                    </Row>
                    <Row style={{ margin: 0, fontSize: '0.98em', marginTop: 6 }}>
                        <p>You'll start receiving all our messages again.</p>
                    </Row>
                    <Row style={{ margin: 0, marginTop: 18 }}>
                        {subscribeButton}
                    </Row>
                </>}
            </Container>

        </Container>
    );
}

export default UnsubscribePage;
